// import React, { useState } from "react";
// import Header from "./pages/Header/Header";
// import Footer from "./pages/Footer/Footer";
// import Home from "./pages/Home/Home";
// import Courses from "./pages/Courses/Courses";
// import InstructorSwiper from "./pages/InstructorSwiper/InstructorSwiper";
// import StudentSwiper from "./pages/StudentSwiper/StudentSwiper";
import { Maincontext } from "./context";
// import { Routes, Route, useNavigate } from "react-router-dom";
// import Connect from "./pages/Connect/Connect";
// import Loading from "./pages/Loading/Loading";
// import ToTop from "./components/MainCoderra/ScrollToTop/ToTop";
// import Sidebar from "./pages/Header/Sidebar/Sidebar";
// import CourseDetail from "./pages/CourseDetail/CourseDetail";
// import CourseInfo from "./pages/CourseInfo/CourseInfo";
// import Chatwhatsapp from "./components/MainCoderra/ChatWhatsApp/Chatwhatsapp";
// import Modalsearch from "./pages/ModalSearch/Modalsearch";
import Setter from './pages/Setter'
function App() {
	// const [sidebar, setSidebar] = useState(false);
	// const [loading, setLoading] = useState(true);
	// const [apply, setApply] = useState(false);
	// const [forCourseApply, setForCourseApply] = useState(false);
	// const [value, setValue] = useState('');
	// let navigate = useNavigate();
	// const popupOpen = (e) => {
	// 	e.preventDefault();
	// 	navigate("*");
	// 	e.target.children[1].value = "";
	// };
	// setTimeout(() => {
	// 	setLoading(false);
	// }, 5000);
	const data = {
		// apply,
		// setApply,
		// forCourseApply,
		// setForCourseApply,
		// sidebar,
		// setSidebar,
		// popupOpen,
		// value, setValue
	};
	return (
		<Maincontext.Provider value={data}>
			<Setter />
			{/* {loading ? (
				<Loading />
			) : (
				<div className="App">
					<Header />
					<Sidebar />
					<Chatwhatsapp />
					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="/courses" element={<Courses />} />
						<Route path="/courses:title" element={<CourseInfo />} />
						<Route path="/instructorswiper" element={<InstructorSwiper />} />
						<Route path="/studentswiper" element={<StudentSwiper />} />
						<Route path="/connect" element={<Connect />} />
						<Route path="/coursedetail" element={<CourseDetail />} />
						<Route path="/*" element={<Modalsearch />} />
					</Routes>
					<ToTop />
					<Footer />
				</div>
			)} */}
		</Maincontext.Provider>
	);
}

export default App;
